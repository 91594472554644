<template>
  <div class="timeline-container">
    <!-- Overlay -->
    <div class="overlay">
      <!-- Columna izquierda con icono y texto -->
      <div class="icon">
        <img
          src="@/assets/regalos.gif"
          alt="icon-right"
          class="icon-image"
        >
        <div class="text">
          Mesa de Regalos
        </div>
      </div>
      <div
        ref="overlay2"
        class="overlay2"
      >
        <div class="icon2">
          <img
            src="@/assets/amazon2.png"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div class="text3">
          <strong />
        </div>
        <a
          href="https://www.amazon.com.mx/wedding/registry/3CY7QGVK1OCGY"
          class="rainbow rainbow-1"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ir a mesa de Regalos
        </a>
      </div>
      <div
        ref="overlay3"
        class="overlay3"
      >
        <div class="icon2">
          <img
            src="@/assets/sobre.svg"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div class="text3">
          "La lluvia de sobres, es la tradición de regalar dinero en efectivo en un sobre el dia del evento"
        </div>
      </div>
      <div
        ref="overlay5"
        class="overlay5"
      >
        <div class="icon3">
          <img
            src="@/assets/tarjetabancaria.png"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div class="text3">
          Tarjeta Bancaria  <br>  Beneficiario:<br> <strong>Everardo Márquez</strong> 
          <br> Tarjeta de débito: <br><strong>5256 7834 7574 8498</strong>
        </div>
      </div>
    </div>
    <!-- Aquí se agrega el GIF debajo de las tarjetas -->
  </div>
</template>
  
  
 <script>
import { onMounted, ref, nextTick } from 'vue';

export default {
  name: 'TimelineComponent',
  setup() {
    const overlay2 = ref(null);
    const overlay3 = ref(null);
    const overlay4 = ref(null);
    const overlay5 = ref(null);


    onMounted(() => {
      nextTick(() => {
        const observerOptions = {
          root: null,
          threshold: [0, 0.5], // Permite que se active en diferentes puntos de visibilidad
        };

        const observerCallback = (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              // Aplicar animación solo si el elemento no tiene ya la clase
              if (!entry.target.classList.contains('animate-fadeInUpScale')) {
                entry.target.classList.add('animate-fadeInUpScale');
              }
            } else {
              // Remover la clase para reiniciar la animación cuando el elemento sale del viewport
              if (entry.target.classList.contains('animate-fadeInUpScale')) {
                entry.target.classList.remove('animate-fadeInUpScale');
              }
            }
          });
        };

        const observer = new IntersectionObserver(observerCallback, observerOptions);

        // Observa los elementos overlay2 y overlay3
        if (overlay2.value) {
          observer.observe(overlay2.value);
        }
        if (overlay3.value) {
          observer.observe(overlay3.value);
        }
        if (overlay4.value) {
          observer.observe(overlay4.value);
        }
        if (overlay5.value) {
          observer.observe(overlay5.value);
        }
      });
    });

    return {
      overlay2,
      overlay3,
      overlay4,
      overlay5
    };
  },
};


</script>


  
  <style scoped>
/* Contenedor general del timeline */
/* Contenedor general del timeline */
.timeline-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 0;
  background-image: url('../assets/bg26.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  z-index: 0;
  min-height: 130vh; /* Asegura un mínimo para evitar problemas en pantallas grandes */
  height: auto; /* Ajusta la altura del contenedor según el contenido */
  padding-bottom: 20px; /* Agrega un pequeño padding al final para evitar recorte del contenido */
}

/* Línea vertical */
.timeline-line {
  width: 2px;
  background-color: rgba(255, 255, 255, 0);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  z-index: -2;
  pointer-events: none;
  height: 100%; /* Ajusta la altura para que cubra el contenedor */
}

/* Ajusta el padding del timeline-container si es necesario */
.timeline-container::after {
  content: "";
  display: block;
  height: 1px; /* Un ajuste mínimo para evitar el recorte del contenido */
  margin-bottom: -1px; /* Compensa el margen para evitar el espacio vacío */
}



/* Columnas para iconos y texto */
.timeline-left,
.timeline-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 300px;
  padding: 10px;
  box-sizing: border-box;
  z-index: 10;
}

/* Icono */
.icon {
  margin-bottom: 20px;
  margin-top: 10px;
  transform: scale(0.6); /* Escala el ícono por un factor de 1.5 */
  height: auto; /* Mantiene las proporciones */
}
.icon2 {
  margin-bottom: 20px;
  margin-top: 10px;
  transform: scale(1.5); /* Escala el ícono por un factor de 1.5 */
  height: auto; /* Mantiene las proporciones */
}


/* Icono de imagen */
.icon-image {
  width: 100%;
  max-width: 100px;
  height: auto;
}

/* Texto */
.text {
  font-size: 38px;
  text-align: center;
  color: #fffafa;
  font-family: 'Dancing Script', cursive;
  font-weight: 600;
}

.text2 {
  font-size: 16px;
  text-align: center;
  color: rgba(46, 44, 44, 0.9);
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: 'Dancing Script', cursive;
  font-weight: 300;
}

.text3 {
  font-size: 15px;
  text-align: center;
  color: rgba(42, 40, 40, 0.845);
  font-family: 'Josefin Sans', sans-serif;
  margin-top: 0px;
  margin-bottom: 20px;
  font-weight: 350;
}

/* Ajustes de overlay2 */
.overlay2,
.overlay3,.overlay5 { /* Aplica los mismos estilos a overlay3 */
  background-color: rgba(255, 255, 255, 0.8); /* Fondo blanco con opacidad */
  border-radius: 10px; /* Bordes redondeados (opcional) */
  padding: 20px; /* Espaciado interno */
  margin: 10px 0; /* Margen vertical para separar de otros elementos */
  display: flex;
  margin-top: 10px;
  flex-direction: column; /* Disposición en columna */
  justify-content: center; /* Centrar verticalmente el contenido */
  align-items: center; /* Centrar horizontalmente el contenido */
  width: 90%; /* Ancho del overlay como un porcentaje del contenedor padre */
  max-width: 600px; /* Ancho máximo para limitar el tamaño máximo */
  text-align: center; /* Centra el texto */

  opacity: 0; /* Oculta los elementos inicialmente */
  transform: translateY(30px); /* Posiciona los elementos fuera del viewport verticalmente */
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; /* Suaviza la transición para la visibilidad y el movimiento */

}
/* Estilos para cuando los elementos son visibles */
.overlay2.animate-fadeInUpScale,
.overlay3.animate-fadeInUpScale,.overlay5.animate-fadeInUp {
  opacity: 1; /* Los elementos serán visibles */
  transform: translateY(0); /* Los elementos se moverán a su posición original */
}

/* Ajustes para el ícono en overlay2 y overlay3 */
.overlay2 .icon2,
.overlay3 .icon2 ,.overlay5.icon2{ /* Aplica los mismos estilos a los íconos en overlay3 */
  
  margin-bottom: 5px; /* Espacio entre el ícono y el texto */
}

.overlay2 .icon-image,
.overlay3 .icon-image ,.overlay5.icon-image{ /* Aplica los mismos estilos a las imágenes en overlay3 */
  height: auto; /* Mantiene las proporciones */
  width: 70px; /* Reduce el ancho del ícono */

}

/* Ajustes de overlay */
/* Ajustes de overlay */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%; /* Cambiar height a min-height para permitir ajuste automático */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: white;
  z-index: 2;
  padding: 10px 20px 20px;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(8px);
  transition: background-color 0.5s ease, backdrop-filter 0.5s ease;
  box-sizing: border-box; /* Asegura que el padding se incluya en la altura total */
}



/* Estilos rainbow */
/* Estilos para que el elemento tenga apariencia de botón */
/* Estilos para que el enlace tenga apariencia de botón */
.rainbow {
  display: inline-block;
  
  font-size: 10px;
  text-align: center;
  color: rgba(35, 31, 31, 0.9);
  font-family: 'Josefin Sans', sans-serif;


  text-transform: uppercase;
  text-decoration: none;
  background-image: linear-gradient(45deg, #c49d9d, #a09a94, #b7a9b7, #7e757e, #80757b, #f2eff4, #6c5f5f);
  background-size: 400%;
  padding: 12px 24px; /* Espaciado interno del botón */
  border: 2px solid transparent; /* Borde transparente para evitar un borde visible inicialmente */
  border-radius: 5px; /* Esquinas redondeadas */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Sombra sutil para el botón */
  transition: background-position 0.5s ease, transform 0.2s ease; /* Transición suave para el gradiente y el efecto de escala */
  cursor: pointer; /* Cambia el cursor para indicar que es clickeable */
  z-index: 20;
}

/* Efecto de hover para el botón */
.rainbow:hover {
  background-position: 100% 100%;
  transform: scale(1.05); /* Escala ligeramente el botón al pasar el ratón */
}

/* Efecto de foco para accesibilidad */
.rainbow:focus {
  outline: none; /* Elimina el borde por defecto del foco */
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2); /* Agrega un borde sutil al enfocarse */
}

/* Efecto de animación del gradiente al inicio */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: 100% 100%;
  }
}

.rainbow {
  animation: gradientAnimation 5s linear infinite; /* Aplica la animación del gradiente al botón */
}






/* Animación de desplazamiento hacia arriba */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Clase de animación aplicada a los elementos cuando se vuelven visibles */
.animate-fadeInUp {
  animation: fadeInUp 1s ease-out forwards;
}



/* Animación de desplazamiento hacia arriba y escala */
@keyframes fadeInUpScale {
  from {
    opacity: 0;
    transform: translateY(30px) scale(0.8); /* Escala inicial más pequeña */
  }
  to {
    opacity: 1;
    transform: translateY(0) scale(1); /* Escala original */
  }
}

/* Clase de animación aplicada a los elementos cuando se vuelven visibles */
.animate-fadeInUpScale {
  animation: fadeInUpScale 0.5s ease-out forwards;
}

  </style>
  