<template>
  <div class="timeline-container">
    <!-- Overlay -->
    <div class="overlay">
      <div class="overlay-text" />
      <div class="text2 stylish-text">
        ¿Dónde y Cuándo?
      </div>
      <!-- Columna izquierda con icono y texto -->
      <div
        ref="leftRef"
        class="timeline-left"
      >
        <div class="icon">
          <img
            src="@/assets/iglesia.svg"
            alt="icon-left"
            class="icon-image"
          >
        </div>
        <div class="text">
          Parroquia María Auxiliadora
        </div>
        <div class="text2">
          Calle Mina y Joaquin A. Kerlengand #916 <br> Col. Independencia.
        </div>
        <div class="text3">
          Hora: 16:00 hrs
        </div>
        <a
          href="https://maps.app.goo.gl/vREErWgSZw6BRPiX9"
          class="modern-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ubicación</a>
      </div>
        
      <!-- Línea vertical -->
      <div class="timeline-line" />
        
      <!-- Columna derecha con icono y texto -->
      <div
        ref="rightRef"
        class="timeline-right"
      >
        <div class="icon">
          <img
            src="@/assets/brindis.svg"
            alt="icon-right"
            class="icon-image"
          >
        </div>
        <div class="text">
          Salón D' Karenina
        </div>
        <div class="text2">
          Blvd. Fidel Velazquez 1325 <br> Col. Revolución Verde.
        </div>
        <div class="text3">
          Hora: 21:00 hrs
        </div>
        <a
          href="https://maps.app.goo.gl/yFBfCeHArjU55Gw77"
          class="modern-button"
          target="_blank"
          rel="noopener noreferrer"
        >
          Ubicación</a>
      </div>
    </div>
  </div>
</template>
    
  <script>
  import { onMounted, ref, nextTick } from 'vue';
  
  export default {
    name: 'TimelineComponent',
    setup() {
      const leftRef = ref(null);
      const rightRef = ref(null);
  
      onMounted(() => {
        nextTick(() => {
          const elements = [leftRef.value, rightRef.value];
  
          const observerOptions = {
            root: null,
            threshold: 0.1,
          };
  
          const observerCallback = (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('animate-fadeInUp');
                entry.target.addEventListener(
                  'animationend',
                  () => {
                    entry.target.classList.remove('animate-fadeInUp');
                  },
                  { once: true }
                );
              }
            });
          };
  
          const observer = new IntersectionObserver(observerCallback, observerOptions);
  
          elements.forEach((element) => {
            if (element) {
              observer.observe(element);
            }
          });
        });
      });
  
      return {
        leftRef,
        rightRef,
      };
    },
  };
  </script>
    
  <style scoped>
  /* Contenedor general del timeline */
  .timeline-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    min-height: 100vh; /* Altura mínima para cubrir la ventana gráfica */
    padding: 0; /* Añade padding horizontal para evitar el desbordamiento */
    box-sizing: border-box;
    overflow-x: hidden; /* Evita el desplazamiento horizontal */
    margin: 0;
    background-image: url('../assets/fondo7.png');
    background-position: center; /* Centra la imagen */
    z-index: 0;
  }
  /* Pseudo-elemento para la imagen de fondo con desenfoque */
.timeline-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../assets/fondo7.png');
  background-position: center; /* Centra la imagen */
  background-size: cover; /* Asegura que la imagen cubra todo el contenedor */
  filter: blur(3px); /* Ajusta el valor de desenfoque según sea necesario */
  z-index: -1; /* Debe estar detrás de los demás elementos */
}
  /* Línea vertical */
  .timeline-line {
    width: 2px;
    background-color: rgba(255, 255, 255, 0); /* Línea completamente transparente */
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    box-sizing: border-box; /* Incluye el borde en el ancho total */
    z-index: -2; /* Asegúrate de que el z-index sea menor que el de los otros elementos */
    pointer-events: none; /* Esto hará que la línea vertical no reciba eventos de puntero */

  }
  
  /* Columnas para iconos y texto */
  .timeline-left,
  .timeline-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 300px; /* Ajusta el ancho máximo para evitar que las columnas se hagan demasiado grandes */
    padding: 10px;
    box-sizing: border-box; /* Incluye el relleno en el ancho total */
    z-index: 10; /* Asegúrate de que el z-index sea menor que el de los otros elementos */

  }
  
  /* Icono */
  .icon {
    
    margin-bottom: 10px;
    margin-top: 10px;

  }
  
  /* Icono de imagen */
  .icon-image {
    width: 50%;
    max-width: 50px; /* Limita el ancho máximo de la imagen */
    height: auto; /* Mantiene la proporción de la imagen */
    animation: pulse 2s infinite; /* Duración de 2 segundos y repetición infinita */
  }
  
  /* Texto */
  .text {
    font-size: 20px;
    text-align: center;
    color: #1a1818cd;
    font-family: 'Dancing Script', cursive;
    font-weight: 600;
  }
  
  .text2 {
    font-size: 16px;
    text-align: center;
    color: rgba(20, 19, 19, 0.9);
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 10px; /* Espacio inferior */
    
    margin-left: 25px; /* Ajusta el valor según sea necesario */
    margin-right: 25px; /* Ajusta el valor según sea necesario */

  }
  .text3 {
    font-size: 12px;
    text-align: center;
    color: rgba(46, 44, 44, 0.9);
    font-family: 'Josefin Sans', sans-serif;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 10px; /* Espacio inferior */
    font-weight: 600;

  }
  
  /* Animaciones */
  @keyframes chicoagrande {
  0% {
    transform: scale(0.5); /* Tamaño inicial */
  }

  100% {
    transform: scale(1); /* Volver a tamaño original */
  }
}

  
  .animate-fadeInUp {
    animation: chicoagrande 1s ease-out forwards;
}
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start; /* Alinea los elementos desde la parte superior */
    align-items: center; /* Centra horizontalmente los elementos */
    color: rgba(0, 0, 0, 0.527);
    z-index: 2;
    padding: 80px 20px 20px,20px; /* Aumenta el padding superior para mover todo el contenido hacia abajo */
    text-align: center;
    overflow: visible; /* Asegura que el contenido visible se muestre correctamente */
}
  
  /* Texto del overlay */
  .overlay-text {
    font-size: 20px;
    font-family: 'Dancing Script', cursive;
    font-weight: 600;
    margin: 50px;
  }
  
 
  
  
  /* Animación para aumentar y disminuir tamaño */
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1); /* Aumenta el tamaño al 110% */
    }
    100% {
      transform: scale(1);
    }
  }
 /* Alternativa para el texto "¿Dónde y Cuándo?" */
.stylish-text {
  font-size: 26px;
  font-family: 'Dancing Script', cursive;
  font-weight: 800;
  margin: 0 30px;
  background: linear-gradient(270deg, #393433, #d0c4c4, #7d5f59);
  background-size: 200% 100%;
  background-clip: text;
  color: transparent;
  margin-top: -20px;
  animation: animateText 5s linear infinite;
}
@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}

/* Alternativa con un fondo degradado */


/* Estilo para botones modernos */
.modern-button {
  display: inline-block;
  text-decoration: none;
  font-size: 12px;
  background: linear-gradient(45deg, #807c7c, #d3d1ce);
  color: white;
  font-family: 'Roboto', cursive;
  padding: 12px 24px;
  border: none;
  border-radius: 25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, background 0.5s ease;
  margin-bottom: 15px;
}



.modern-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
}

  </style>
  