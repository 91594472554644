<!-- Fondo.vue -->
<template>
  <div class="fixed-background" />
</template>
  
  <script>
  export default {
    name: 'FondoComponenteS',
  };
  </script>
  
  <style scoped>
  .fixed-background {
    position: fixed; /* Imagen fija */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('../assets/fondo2.jpg');
    background-size: cover; /* Cubrir todo el contenedor */
    background-position: center;
    background-repeat: no-repeat; /* No repetir la imagen */
    z-index: -1; /* Detrás del contenido */
  }
  </style>
  