<template>
  <div>
    <!-- Contenedor Bienvenida -->
    <div
      ref="bienvenidaRef"
      class="bienvenida-container"
    >
      <p class="bienvenida-text">
        ¡El momento que siempre hemos soñado ha llegado!
      </p>
      <p
        ref="bienvText2Ref"
        class="bienvenida-text2"
      >
        <br>¡Una boda se vive tres veces: al soñarla, al celebrarla y al recordarla.<br><br><br>
      </p>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, nextTick } from 'vue';
import 'animate.css';

export default {
  name: 'BienvenidaPage',
  setup() {
    const bienvText2Ref = ref(null);

    onMounted(async () => {
      await nextTick();

      const applyAnimation = (element, animationClasses) => {
        if (element) {
          element.classList.remove(...animationClasses);
          void element.offsetWidth; // Trigger reflow to restart animation
          element.classList.add(...animationClasses);
          element.addEventListener(
            'animationend',
            () => {
              element.style.opacity = '1';
            },
            { once: true }
          );
        }
      };

      const createObserver = (element, animationClasses) => {
        const observerOptions = { root: null, rootMargin: '0px', threshold: [0.1] };

        const observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              applyAnimation(element, animationClasses);
            } else {
              element.style.opacity = '0'; // Hide when out of view
            }
          });
        }, observerOptions);

        observer.observe(element);
      };

      // Array of elements to apply the observer and animations
      const elements = [
        { element: bienvText2Ref.value, animationClasses: ['animate__animated', 'animate__fadeInUp'] }
      ];

      // Apply the observer to each element in the array
      elements.forEach(({ element, animationClasses }) => {
        if (element) {
          createObserver(element, animationClasses);
        }
      });
    });

    return { bienvText2Ref };
  }
};
</script>

<style scoped>
.bienvenida-container {
  position: relative;
  width: 100%;
  padding-top: 30px;
  margin: 0;
  text-align: center;
  background: url('../assets/fondo_bienv.png') center center / cover no-repeat;
  opacity: 1;
  transform: translateY(0);
  z-index: 1;
  font-family: 'Dancing Script', cursive !important;
  display: flex;
  flex-direction: column;
}

.bienvenida-text {
  font-size: 26px;
  font-family: 'Dancing Script', cursive;
  font-weight: 800;
  margin: 0 30px;
  background: linear-gradient(270deg, #393433, #d0c4c4, #7d5f59);
  background-size: 200% 100%;
  background-clip: text;
  color: transparent;
  animation: animateText 5s linear infinite;
}

.bienvenida-text2 {
  font-size: 18px;
  color: #656253;
  margin: 0 30px;
  white-space: pre-line;
  font-family: 'Karla';
}

.animate__animated {
  animation-fill-mode: both;
}

@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(https://fonts.gstatic.com/s/karla/v31/qkBIXvYC6trAT55ZBi1ueQVIjQTDeJqaE0lK.woff2) format('woff2');
}

@keyframes animateText {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: 0 0;
  }
}
</style>
