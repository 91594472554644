<template>
  <div class="background-container">
    <!-- Imagen de fondo fija -->
    <Fondo /> <!-- Aquí se incluye el componente de fondo -->
    
    <!-- Contenedor del contenido superpuesto -->
    <div class="content-overlay">
      <p class="overlay-text">
        04 de Enero 2025
      </p>
      <p class="overlay-text2">
        {{ typedText }}
      </p>
    </div>
  </div>
</template>

<script>
import Fondo from './Fondo_componente.vue'; // Importa el nuevo componente

export default {
  name: 'FullWidthImage',
  components: {
    Fondo, // Registra el componente
  },
  data() {
    return {
      typedText: '', // Texto a mostrar con efecto de escritura
      fullText: 'Nos encantaría que lo celebres con nosotros y seas parte de nuestros recuerdos.',
    };
  },
  mounted() {
    this.typeText();
  },
  methods: {
    typeText() {
      let index = 0;
      const typingDelay = 100; // Retraso en milisegundos

      const typeLetter = () => {
        if (index < this.fullText.length) {
          this.typedText += this.fullText.charAt(index);
          index++;
          setTimeout(typeLetter, typingDelay);
        }
      };

      typeLetter();
    },
  },
};
</script>

<style scoped>
/* Contenedor principal */
.background-container {
  position: relative;
  width: 100%;
  height: 100vh; /* Ocupa toda la altura del viewport */
  overflow: hidden;
  background-repeat: no-repeat; /* No repetir la imagen */

}

/* Contenedor para el texto superpuesto */
.content-overlay {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%; /* Igual altura que el fondo */
  background-color: rgba(68, 63, 63, 0.6); /* Fondo blanco semitransparente */
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

.overlay-text {
  font-size: 20px;
  color: #766e6b;
  margin: 0 20px;
  font-weight: 200;
  font-family: 'Roboto';
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.7), 0 0 20px rgba(255, 255, 255, 0.5); /* Resplandor */
  animation: glow 1.5s ease-in-out infinite alternate; /* Animación de resplandor */
}

/* Animación de pulsación */
@keyframes glow {
  0% {
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.7), 0 0 20px rgba(255, 255, 255, 0.5);
    color: #b7b2b0; /* Color normal */
  }
  100% {
    text-shadow: 0 0 20px rgba(255, 255, 255, 1), 0 0 30px rgba(255, 255, 255, 0.8);
    color: #f8f8f8; /* Color iluminado */
  }
}

.overlay-text2 {
  font-size: 26px;
  color: #ffffffc8;
  margin-top: 10px;
  font-family: 'Dancing Script', sans-serif;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Sombra sutil */
  /* O para un resplandor */
  /* text-shadow: 0 0 8px rgba(255, 255, 255, 0.7); */ /* Resplandor */
}
</style>
