<template>
  <div id="app">
    <InicioLogo v-if="isSmallScreen" />
    <Componentep
      v-if="isSmallScreen"
      :id-invitado="idInvitado"
    />
    <pantalla-g v-else /> <!-- Aquí se muestra el componente pantalla_g.vue si no es pequeña -->
  </div>
</template>

<script>
import { ref, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import InicioLogo from './components/inicio.vue';
import Componentep from './components/contenedor.vue';
import PantallaG from './components/pantallas_g.vue'; // Asegúrate de importar el componente

export default {
  name: 'App',
  components: {
    InicioLogo,
    Componentep,
    PantallaG,
  },
  setup() {
    const route = useRoute();
    const idInvitado = ref(Number(route.params.idInvitado) || undefined);
    const idFestejado = ref(Number(route.params.idFestejado) || undefined);
    const isSmallScreen = ref(window.innerWidth < 768); // Determina si la pantalla es pequeña

    const isValidIdInvitado = computed(() => !isNaN(idInvitado.value) && idInvitado.value !== undefined);
    const isValidFestejado = computed(() => !isNaN(idFestejado.value) && idFestejado.value !== undefined);

    // Watchers para actualizar los IDs
    watch(() => route.params.idInvitado, (newId) => {
      idInvitado.value = Number(newId);
    });

    watch(() => route.params.idFestejado, (newId) => {
      idFestejado.value = Number(newId);
    });

    // Listener para detectar cambios en el tamaño de la pantalla
    window.addEventListener('resize', () => {
      isSmallScreen.value = window.innerWidth < 768;
    });

    return {
      idInvitado,
      idFestejado,
      isSmallScreen,
      isValidIdInvitado,
      isValidFestejado,
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
